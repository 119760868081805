import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { account } from "../../services/account";
import './reset.scss';

export function ResetPage() {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const location = useLocation();
    const search = location.search;
    const params = new URLSearchParams(search);
    const auth = params.get('auth');
    const navigate = useNavigate();

    function updatePassword() {
        if (auth && newPassword === confirmPassword) {
            account.updatePassword(auth, newPassword).then(() => { navigate('/dashboard') });
        }
    }

    return <div className="reset">
        <div className="entry-field">
            <label className="entry-label">New Password</label>
            <input type="password" value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }} />
        </div>
        <div className="entry-field">
            <label className="entry-label">Confirm Password</label>
            <input type="password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
        </div>
        <button className="button" onClick={updatePassword}>Update Password</button>
    </div >
}