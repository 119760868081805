import { Album, UserShare } from "../model/album";
import { api } from "./api";

export const albumService = {
    getAlbum,
    getAlbums,
    getShares,
    updateShares,
    createAlbum,
    updateAlbum
}

async function getAlbum(id: number): Promise<Album> {
    return await api.get(`/album/${id}`);
}

async function getAlbums(): Promise<Album[]> {
    return await api.get('/album');
}

async function getShares(albumId: number): Promise<UserShare[]> {
    return await api.get(`/album/${albumId}/shares`)
}

async function updateShares(albumId: number, shares: UserShare[]): Promise<void> {
    return await api.put(`/album/${albumId}/shares`, shares);
}

async function createAlbum(albumName: string): Promise<Album> {
    return await api.post(`/album/${albumName}`, null);
}

async function updateAlbum(album: Album): Promise<void> {
    return await api.put('/album', album);
}