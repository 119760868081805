const environment = process.env;
const baseUrl = environment.NODE_ENV === 'production' ? 'https://api.barrass.cloud' : 'https://localhost:7179';

export const api = {
    baseUrl,
    get,
    post,
    put,
    deleteItem
}


function get<T>(path: string): Promise<T> {
    const inc: RequestCredentials = 'include';
    const options = {
        method: 'GET',
        credentials: inc
    };

    return fetch(baseUrl + path, options).then(r => r.json());
}

async function deleteItem(path: string): Promise<void> {
    const inc: RequestCredentials = 'include';
    const options = {
        method: 'DELETE',
        credentials: inc
    };

    await fetch(baseUrl + path, options);
}

async function post<T>(path: string, body: any): Promise<T> {
    const inc: RequestCredentials = 'include';
    const options = {
        method: 'POST',
        credentials: inc,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    };

    var result = await fetch(baseUrl + path, options);
    if (!result.ok)
        return Promise.reject(result.status);
        
    var contentLength = result.headers.get('content-length');
    if (contentLength && parseInt(contentLength) > 0) {
        return result.json();
    }
    const noResult: any = {};
    return Promise.resolve(noResult);
}


async function put(path: string, body: any): Promise<void> {
    const inc: RequestCredentials = 'include';
    const options = {
        method: 'PUT',
        credentials: inc,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
    };

    await fetch(baseUrl + path, options);
}