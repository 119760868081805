import { useState } from "react"
import './home.scss';
import { Login } from "./login";
import { SignUp } from "./signup";

export function Home(): JSX.Element {
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showSignUp, setShowSignUp] = useState<boolean>(false);

    function startLogin() {
        setShowLogin(true);
        setShowSignUp(false);
    }

    function startSignup() {
        setShowLogin(false);
        setShowSignUp(true);
    }

    return <div className="home">
        <div className={showLogin ? "login-area selected" : showSignUp ? "login-area unselected" : "login-area"}>
            <div className={showLogin ? 'login-show' : 'login-hide'}>
                <Login />
            </div>
            <div className="home-title" onClick={startLogin}>Login</div>
        </div>
        <div className={showSignUp ? "signUp-area selected" : showLogin ? "signUp-area unselected" : "signUp-area"}>               
            <div  className="home-title" onClick={startSignup}>Sign up</div>
            <div className={showSignUp ? 'signup-show' : 'signup-hide'}>
                <SignUp />
            </div>
        </div>
    </div>
}