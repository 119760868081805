import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { account } from "../../services/account";
import './login.scss'

export function Login(): JSX.Element {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [resetPassword, setResetPassword] = useState<boolean>(false);
    const [loginFailed, setLoginFailed] = useState<boolean>(false);
    const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
    const navigate = useNavigate();

    async function login() {
        if (resetPassword)
            account.resetPassword(email)
        else {
            try {
                await account.logIn({ email: email, password: password });
                setLoginSuccess(true);
                navigate('/dashboard');
            } catch {
                setLoginFailed(true);
                setTimeout(() => setLoginFailed(false), 1000);
            }
        }
    }

    return <div className='login'>
        <div className='entry-field'>
            <label className="entry-label">Email</label>
            <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
        </div>
        {resetPassword ? null :
            <div className='entry-field'>
                <label className="entry-label">Password</label>
                <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
            </div>
        }
        <button className={loginSuccess? "good-login button" :loginFailed ? "bad-login button" : "button"} onClick={login}>{resetPassword ? "Request Reset" : "Log In"}</button>
        <button className="small-button" onClick={() => { setResetPassword(r => !r); }}>{resetPassword ? "I've remembered" : "Forgotten Password"}</button>
    </div >
}