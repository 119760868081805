import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { account } from "../../services/account";
import './signup.scss';

export function SignUp(): JSX.Element {
    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const navigate = useNavigate();
    
    async function signup() {
        await account.signUp({email:email, name:username, password:password});
        navigate('/dashboard');
    }

    return <div className='signup'>
        <div className='entry-field'>
            <label className="entry-label">Username</label>
            <input type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
        </div>
        <div className='entry-field'>
            <label className="entry-label">Email</label>
            <input type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
        </div>
        <div className='entry-field'>
            <label className="entry-label">Password</label>
            <input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
        </div>
        <button className="button" onClick={signup}>Create account</button>
    </div>
}