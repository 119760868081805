import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.scss';
import { AlbumPage } from './pages/album/album';
import { Dashboard } from './pages/dashboard/dashboard';
import { Home } from './pages/home/home';
import { ResetPage } from './pages/reset/reset';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="album/:id" element={<AlbumPage />} />
        <Route path="reset" element={<ResetPage />} />
        <Route path="" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
