import { useState } from "react";
import { Album } from "../../model/album";
import { ContentView } from "../album/content";
import { AlbumSettings } from "./albumSettings";
import './album.scss';

export function AlbumCover(props: { album: Album; onClick: () => void }): JSX.Element {
    const [showSettings, setShowSettings] = useState<boolean>(false);

    function settingsClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        setShowSettings(true);
    }

    return <div className="album" onClick={props.onClick}>
        <div className="album-settings" onClick={settingsClick}><i className="fas fa-cog"/></div>
        <div className="album-cover">{props.album.coverId ? <ContentView content={({ albumId: props.album.id, id: props.album.coverId, type: "image/png" })} /> : null}</div>
        <div className="album-title">{props.album.name}</div>
        {showSettings ? <div className="modal-wrapper" onClick={(e) => { e.stopPropagation(); setShowSettings(false) }}>
            <div className="modal" onClick={(e) => { e.stopPropagation() }}>
                <AlbumSettings album={props.album} close={() => setShowSettings(false)}></AlbumSettings>
            </div>
        </div> : null}
    </div>
}