import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Album } from "../../model/album";
import { albumService } from "../../services/albumService";
import { AlbumCover } from "./albumCover";
import './dashboard.scss';

export function Dashboard(): JSX.Element {
    const [albums, setAlbums] = useState<Album[]>([]);
    const [newAlbum, setNewAlbum] = useState<boolean>(false);
    const [newAlbumName, setNewAlbumName] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Getting albums")
        albumService.getAlbums().then(setAlbums)
    }, [])

    function createNewAlbum() {
        albumService.createAlbum(newAlbumName).then(a => setAlbums(as => [...as, a]));
        setNewAlbum(false);
    }

    return <div className="dashboard">
        {newAlbum ?
            <div className="entry-form"><div className="entry-field">
                <label className="entry-label">Album Name</label>
                <input type='text' value={newAlbumName} onChange={(e) => setNewAlbumName(e.target.value)} />
                <button className="button" onClick={createNewAlbum}>Create</button>
                <button className='button' onClick={() => { setNewAlbum(false) }}>Cancel</button>
            </div></div> : <button className='button' onClick={() => { setNewAlbum(true) }}>Create Album</button>}
        {albums.map(a => <AlbumCover album={a} onClick={() => { navigate(`/album/${a.id}`) }} />)}
    </div>
}