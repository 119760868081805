import { Content } from "../../model/album";
import { api } from "../../services/api";
import "./content.scss";

export function ContentView(props: {content: Content}) :JSX.Element {
    switch(props.content.type) {
        case "video/mp4":  return <video controls={true} className='content-item' src={`${api.baseUrl}/content/${props.content.albumId}/${props.content.id}`}/>
        case "image/png": 
        case "image/jpeg": return <img alt='album entry' className='content-item' src={`${api.baseUrl}/content/${props.content.albumId}/${props.content.id}`}/>
    }
    return <div></div>
}