import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Album, Content } from "../../model/album";
import { FileUpload } from "../../model/fileUpload";
import { albumService } from "../../services/albumService";
import { contentService } from "../../services/contentService";
import { ContentView } from "./content";
import { Circle } from "rc-progress";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import './album.scss';

export function AlbumPage(): JSX.Element {
    const [content, setContent] = useState<Content[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>();
    const [album, setAlbum] = useState<Album>();
    const [albumId, setAlbumId] = useState<number>();
    const [selectedFiles, setSelectedFiles] = useState<FileList>();
    const [uploadingFiles, setUploadingFiles] = useState<FileUpload[]>([]);
    const { id } = useParams();
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const fullscreen = useFullScreenHandle();

    useEffect(() => {
        if (id) {
            const albumId = parseInt(id);
            setAlbumId(albumId);
            albumService.getAlbum(albumId).then(setAlbum);
            contentService.getAlbum(albumId).then(c => {
                setContent(c)
            });
        }
    }, [id])

    function uploadComplete(content: Content, file: FileUpload) {
        setUploadingFiles(uf => {
            const temp = [...uf];
            const i = uf.findIndex(f => f.id === file.id);
            console.log("upload Complete", uf, i, file.id);
            if (i > -1) {
                temp.splice(i, 1);
            }
            console.log("Upload complete result", temp);
            return temp;
        })
        setContent(c => {
            return [...c, content];
        });
    }

    function progressUpdated(id: number, percent: number) {
        setUploadingFiles(uf => {
            const i = uf.findIndex(f => f.id === id);
            console.log("Progress Updated", uf, i, id, percent);
            if (i > -1) {

                const f = uf[i];
                f.percent = percent;
            }
            return [...uf]
        })
    }

    function uploadFiles() {
        //Move files to a protected array       
        if (selectedFiles && albumId) {
            const uploadQueue = [];
            for (const file of selectedFiles) {
                uploadQueue.push(file);
            }
            //clear file selection
            if (albumId && uploadQueue.length) {
                const temp: FileUpload[] = [];
                for (const file of uploadQueue) {
                    const uploadingFile: FileUpload = { id: file.lastModified, file: file, percent: 0 };
                    temp.push(uploadingFile);
                    contentService.uploadFile(albumId, uploadingFile, progressUpdated).then(uploadedContent => {
                        uploadComplete(uploadedContent, uploadingFile);
                    });
                }
                setUploadingFiles(uf => {
                    console.log("Transferred to uploading files", uf, temp);
                    return [...uf, ...temp]
                })
            }
        }
        if (fileUploadRef.current) {
            fileUploadRef.current.value = '';
        }
    }

    function makeCover(c: Content) {
        if (album)
            albumService.updateAlbum({ ...album, coverId: c.id })
    }

    function clearSelection() {
        console.log("CLEAR");
        console.log(fileUploadRef)
        setSelectedFiles(undefined);
        if (fileUploadRef.current) 
            fileUploadRef.current.value = '';
    }

    function deleteContent(c: Content) {
        if (albumId)
            contentService.deleteContent(albumId, c.id);
        const i = content.findIndex(content => content.id === c.id);
        if (i > -1) {
            const temp = [...content];
            temp.splice(i, 1);
            setContent(temp);
        }
    }

    function startSlideShow() {
        if (content.length) {
            setActiveIndex(0);
            fullscreen.enter();
        }
    }

    function previous() {
        setActiveIndex(ai => {
            if (ai !== undefined) {
                if (ai > 0)
                    return ai - 1;
                return content.length - 1;
            }
            return undefined;
        });
    }

    function next() {
        setActiveIndex(ai => {
            if (ai !== undefined) {
                if (ai < content.length)
                    return ai + 1;
                return 0;
            }
            return undefined;
        });
    }

    function exit() {
        fullscreen.exit();
        setActiveIndex(undefined);
    }


    return <div className="album-page">
        <div className="album-header">
            <div className="entry-field">
                {(!selectedFiles) ?
                    <div className="upload-control">
                        <label htmlFor="uploader" className="custom-file-upload"><i className="fa fa-cloud-upload"></i></label>
                        <input id='uploader' multiple={true} hidden={true} type='file' ref={fileUploadRef} onChange={(e) => { if (e.target.files) setSelectedFiles(e.target.files) }}></input>
                    </div>
                    :
                    <div className="upload-control">
                        <button className="button" onClick={clearSelection}><i className="fa-solid fa-delete-left fa-rotate-180"></i></button>
                        <button className="button" onClick={uploadFiles}>{`Upload ${selectedFiles.length} File${selectedFiles.length !== 1 ? "s" : ""}`}</button>
                    </div>
                }
            </div>
            <i onClick={startSlideShow} className="fa-solid fa-maximize"></i>
        </div>
        <div className="album-contents">
            {content.map(c => <div className='album-content' key={c.id}>
                <button className='make-cover-button button' onClick={() => { makeCover(c) }}><i className="fa-solid fa-image" /></button>
                <ContentView content={c} />
                <button className='content-button button' onClick={() => { deleteContent(c) }}><i className="fas fa-trash" /></button>
            </div>)}
            {uploadingFiles.map((uf, i) => <div className='album-content' key={i}><div className="percent-graphic"><Circle percent={uf.percent} strokeWidth={12} /></div><div className="percent">{uf.percent}%</div></div>)}
        </div>
        {activeIndex !== undefined ?
            <FullScreen handle={fullscreen}>
                <div className='full-screen-album'>
                    <ContentView content={content[activeIndex]} />
                    <i onClick={previous} className="previous fa-solid fa-circle-chevron-left"></i>
                    <i onClick={next} className="next fa-solid fa-circle-chevron-right"></i>
                    <i onClick={exit} className="close fa-solid fa-circle-xmark"></i>
                </div>
            </FullScreen> : null}
    </div>
}