import { useEffect, useState } from "react";
import { Album, UserShare } from "../../model/album";
import { account } from "../../services/account";
import { albumService } from "../../services/albumService";
import './albumSettings.scss';

export function AlbumSettings(props: { album: Album; close: () => void }): JSX.Element {
    const [albumName, setAlbumName] = useState<string>(props.album.name!);
    const [addShareUser, setAddShareUser] = useState<string>('');
    const [shares, setShares] = useState<UserShare[]>([]);
    const [users, setUsers] = useState<UserShare[]>([]);
    const [dropVisible, setDropVisible] = useState<boolean>(false);

    useEffect(() => {
        console.log(props.album.id);
        if (props.album.id) {
            albumService.getShares(props.album.id).then(setShares);
        }
    }, [props.album.id]);

    useEffect(() => {
        if (addShareUser) {
            account.search(addShareUser).then(setUsers);
        }
    }, [addShareUser]);

    function addShare(user: UserShare) {
        //add user ID to the album users
        setShares(s => [...s, user]);
        setAddShareUser('');
        setUsers([]);
    }

    function removeShare(user: UserShare) {
        setShares(s => {
            var x = s.indexOf(user);
            s.splice(x, 1);
            return [...s];
        });
    }

    function save() {
        albumService.updateAlbum({
            id: props.album.id,
            name: albumName,
            users: shares.map(i => ({ userId: i.id })),
            coverId: props.album.coverId
        })
        albumService.updateShares(props.album.id, shares);
        props.close();
    }

    return <div className="modal-content">
        <div className="modal-title">Album Settings</div>
        <div className="entry-field">
            <span>Album Name</span>
            <input type={'text'} value={albumName} onChange={(e) => { setAlbumName(e.target.value) }} />
        </div>
        <div className="share-root">
            <div className="share-controls">
                <span>Shared With</span>
                <div className="drop-anchor">
                    <input type='text' value={addShareUser} onChange={(e) => setAddShareUser(e.target.value)} onFocus={() => setDropVisible(true)} onBlur={() => setDropVisible(false)} />
                    {dropVisible ? <div className='floaty-drop'>
                        <ul>
                            {users.map(user => <li key={user.id} onClick={(e) => addShare(user)}>{user.username}</li>)}
                        </ul>
                    </div> : null}
                </div>
            </div>
            <div className='share-list'>
            {shares.map(s => <div className="share">{s.username}<div onClick={() => { removeShare(s) }}><i className="fas fa-trash" /></div></div>)}
            </div>
        </div>
        <div className="modal-footer"><button className='button' onClick={save}>Save Changes</button></div>
    </div>
}