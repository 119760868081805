import axios from "axios";
import { Content } from "../model/album";
import { FileUpload } from "../model/fileUpload";
import { api } from "./api";

export const contentService = {
    getAlbum,
    getContent,
    uploadFile,
    deleteContent
}

async function getAlbum(id: number): Promise<Content[]> {
    return await api.get(`/content/${id}`);
}

async function getContent(albumId: number, id: number): Promise<Content> {
    return await api.get(`/content/${albumId}/${id}`);
}

async function deleteContent(albumId: number, id: number): Promise<void> {
    return await api.deleteItem(`/content/${albumId}/${id}`);
}

function uploadFile(albumId: number, file: FileUpload, onProgress: (id: number, percent: number) => void): Promise<Content> {
    const formPayload = new FormData()
    formPayload.append('albumId', albumId.toString());
    formPayload.append('file', file.file)
    return axios.post(
        '/content/upload',
        formPayload,
        {
            baseURL: api.baseUrl,
            url: '/content/upload',
            method: 'POST',
            withCredentials: true,
            data: formPayload,
            onUploadProgress: progress => {
                const { loaded, total } = progress
                if (total) {
                    const percentageProgress = Math.floor((loaded / total) * 100.0)
                    if (onProgress)
                        onProgress(file.id, percentageProgress);
                }
            }
        },
    ).then(axiosResponse => { return axiosResponse.data; });
}