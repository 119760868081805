import { UserShare } from "../model/album";
import { api } from "./api";

export const account = {
    signUp,
    logIn,
    search,
    resetPassword,
    updatePassword
}

export interface userSignUp {
    name?: string;
    password: string;
    email: string;
}

function signUp(signUpInfo: userSignUp) {
    return api.post('/account/create', signUpInfo);
}

function logIn(signUpInfo: userSignUp) {
    return api.post('/account', signUpInfo);
}

function search(name: string) {
    return api.get<UserShare[]>(`/account?name=${name}`);
}

function resetPassword(email: string) {
    return api.post('/account/request-password-reset', { email: email })
}

function updatePassword(auth: string, newPassword: string) {
    return api.post('/account/reset-password', { email: auth, password: newPassword })
}